import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import LevelConfigOverview from 'src/components/level-config-overview/level-config-overview'
import { usePrivateRoute } from 'src/utils/auth'

const LevelConfigPage = () => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="Level Configuration" />
      <LevelConfigOverview />
    </Layout>
  )
}

export default LevelConfigPage
